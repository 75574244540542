import AppApi from '@/backend/Api';
import { AppApiResponse, Client, ClientQrResponse } from '@/types';
import { ApplyClientFileResponse } from '@/types/ApplyClientFileResponse';
import { AxiosRequestConfig } from 'axios';

class ClientService {
  getClients(): Promise<Client[]> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/clients',
        method: 'GET'
      };

      AppApi.sendRequest<Client[]>(request)
        .then((data: AppApiResponse<Client[]>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getClient(clientId: number): Promise<Client> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/clients/${clientId}`,
        method: 'GET'
      };

      AppApi.sendRequest<Client>(request)
        .then((data: AppApiResponse<Client>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createClient(newClient: Client): Promise<Client> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/clients',
        method: 'POST',
        data: newClient
      };

      AppApi.sendRequest<Client>(request)
        .then((data: AppApiResponse<Client>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateClient(updatedClient: Client): Promise<Client> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/clients',
        method: 'PUT',
        data: updatedClient
      };

      AppApi.sendRequest<Client>(request)
        .then((data: AppApiResponse<Client>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteClient(clientId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/clients/${clientId}`,
        method: 'DELETE'
      };

      AppApi.sendRequest<string>(request)
        .then((data: AppApiResponse<string>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getClientQR(clientId: number): Promise<ClientQrResponse> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/clients/${clientId}/qr`,
        method: 'GET'
      };

      AppApi.sendRequest<ClientQrResponse>(request)
        .then((data: AppApiResponse<ClientQrResponse>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  refreshClientQR(clientId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/clients/${clientId}/qr/refresh`,
        method: 'GET'
      };

      AppApi.sendRequest<string>(request)
        .then((data: AppApiResponse<string>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  applyFile(fileServerId: string): Promise<ApplyClientFileResponse> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/clients/file/apply',
        method: 'POST',
        data: {
          filename: fileServerId
        }
      };

      AppApi.sendRequest<ApplyClientFileResponse>(request)
        .then((data: AppApiResponse<ApplyClientFileResponse>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getClientContactList(clientId: number): Promise<Client[]> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/clients/${clientId}/contacts`,
        method: 'GET'
      };

      AppApi.sendRequest<Client[]>(request)
        .then((data: AppApiResponse<Client[]>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPreviewContactList(clientId: number | null, tagIds: number[]): Promise<Client[]> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/clients/contacts/preview`,
        method: 'POST',
        data: {
          clientId,
          tagIds
        }
      };

      AppApi.sendRequest<Client[]>(request)
        .then((data: AppApiResponse<Client[]>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendAccessToCreatedContacts(locale: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/clients/access/send`,
        method: 'POST',
        data: {
          locale
        }
      };

      AppApi.sendRequest<string>(request)
        .then((data: AppApiResponse<string>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendAccessToContact(clientId: number, locale: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/clients/access/send/${clientId}`,
        method: 'POST',
        data: {
          locale
        }
      };

      AppApi.sendRequest<string>(request)
        .then((data: AppApiResponse<string>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new ClientService();
