import TagService from '@/backend/service/TagService';
import { Tag } from '@/types';
import { defineStore } from 'pinia';

export const useTagStore = defineStore('tag', {
  state: () => ({
    tags: [] as Tag[]
  }),
  getters: {
    getTags(state) {
      return state.tags;
    },
    getTagById(state) {
      return (tagId: number): Tag | undefined => {
        return state.tags.find((tag) => tag.id === tagId);
      };
    }
  },
  actions: {
    fetchTags(): Promise<Tag[]> {
      return new Promise((resolve, reject) => {
        TagService.getTags()
          .then((listTags: Tag[]) => {
            this.tags = listTags;
            resolve(listTags);
          })
          .catch((error) => reject(error));
      });
    },
    fetchTag(tagId: number): Promise<Tag> {
      return new Promise((resolve, reject) => {
        TagService.getTag(tagId)
          .then((tag: Tag) => {
            resolve(tag);
          })
          .catch((error) => reject(error));
      });
    },
    createTag(newTags: Tag[]): Promise<Tag[]> {
      return new Promise((resolve, reject) => {
        TagService.createTags(newTags)
          .then((createdTags: Tag[]) => {
            this.tags = this.tags.concat(createdTags);
            resolve(createdTags);
          })
          .catch((error) => reject(error));
      });
    },
    updateTag(updatedTag: Tag): Promise<Tag> {
      return new Promise((resolve, reject) => {
        TagService.updateTag(updatedTag)
          .then((responseUpdateTag: Tag) => {
            const existedtagIndex = this.tags.findIndex((tag: Tag) => tag.id === responseUpdateTag.id);
            if (existedtagIndex > -1) {
              this.tags[existedtagIndex] = responseUpdateTag;
            }
            resolve(responseUpdateTag);
          })
          .catch((error) => reject(error));
      });
    },
    deleteTag(tagId: number): Promise<string> {
      return new Promise((resolve, reject) => {
        this.setTagInProcess(tagId, true);
        TagService.deleteTag(tagId)
          .then((response: string) => {
            const existedtagIndex = this.tags.findIndex((tag: Tag) => tag.id === tagId);
            if (existedtagIndex > -1) {
              this.tags = this.tags.filter((tag: Tag) => tag.id != tagId);
            }
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => this.setTagInProcess(tagId, false));
      });
    },
    setTagInProcess(tagId: number, currentValue: boolean) {
      const existedtagIndex = this.tags.findIndex((tag: Tag) => tag.id === tagId);
      if (existedtagIndex > -1) {
        this.tags[existedtagIndex].inProcess = currentValue;
      }
    }
  }
});
