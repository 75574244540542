import AppApi from '@/backend/Api';
import { AppApiResponse, Tag } from '@/types';
import { AxiosRequestConfig } from 'axios';

class TagService {
  getTags(): Promise<Tag[]> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/tags',
        method: 'GET'
      };

      AppApi.sendRequest<Tag[]>(request)
        .then((data: AppApiResponse<Tag[]>) => {
          resolve(data.data);
        })
        .catch((error) => {          
          reject(error);
        });
    });
  }

  getTag(tagId: number): Promise<Tag> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/tags/${tagId}`,
        method: 'GET'
      };

      AppApi.sendRequest<Tag>(request)
        .then((data: AppApiResponse<Tag>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createTags(newTags: Tag[]): Promise<Tag[]> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/tags',
        method: 'POST',
        data: newTags
      };

      AppApi.sendRequest<Tag[]>(request)
        .then((data: AppApiResponse<Tag[]>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateTag(updatedTag: Tag): Promise<Tag> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: '/tags',
        method: 'PUT',
        data: updatedTag
      };

      AppApi.sendRequest<Tag>(request)
        .then((data: AppApiResponse<Tag>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteTag(tagId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const request: AxiosRequestConfig = {
        url: `/tags/${tagId}`,
        method: 'DELETE'
      };

      AppApi.sendRequest<string>(request)
        .then((data: AppApiResponse<string>) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new TagService();
