import ClientService from '@/backend/service/ClientService';
import { Client, ClientQrResponse } from '@/types';
import { ApplyClientFileResponse } from '@/types/ApplyClientFileResponse';
import ClientStatus from '@/types/ClientStatus';
import { defineStore } from 'pinia';

export const useClientStore = defineStore('client', {
  state: () => ({
    clients: [] as Client[]
  }),
  getters: {
    getClients(state) {
      return state.clients;
    },
    getClientsForSendAccess(state) {
      return state.clients.filter(
        (client) => client.status == ClientStatus.CREATED || client.status == ClientStatus.RECREATED
      );
    },
    getClientById(state) {
      return (clientId: number): Client | undefined => {
        return state.clients.find((client) => client.id === clientId);
      };
    }
  },
  actions: {
    fetchClients(): Promise<Client[]> {
      return new Promise((resolve, reject) => {
        ClientService.getClients()
          .then((listClients: Client[]) => {
            this.clients = listClients.map((client) => {
              client.isSelected = false;
              return client;
            });
            resolve(listClients);
          })
          .catch((error: any) => reject(error));
      });
    },
    setIsSelectedClient(clientId: number, _isSelected: boolean) {
      const findedIndex = this.clients.findIndex((client) => client.id === clientId);
      if (findedIndex > -1) {
        this.clients[findedIndex].isSelected = _isSelected;
      }
    },
    fetchClient(clientId: number): Promise<Client> {
      return new Promise((resolve, reject) => {
        ClientService.getClient(clientId)
          .then((client: Client) => {
            resolve(client);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
    createClient(newClient: Client): Promise<Client> {
      return new Promise((resolve, reject) => {
        ClientService.createClient(newClient)
          .then((createdClient: Client) => {
            this.clients.push(createdClient);
            resolve(createdClient);
          })
          .catch((error: any) => reject(error));
      });
    },
    createManyClients(serverFileId: string): Promise<ApplyClientFileResponse> {
      return new Promise((resolve, reject) => {
        ClientService.applyFile(serverFileId)
          .then((response: ApplyClientFileResponse) => {
            resolve(response);
          })
          .catch((error: any) => reject(error));
      });
    },
    updateClient(updatedClient: Client): Promise<Client> {
      return new Promise((resolve, reject) => {
        ClientService.updateClient(updatedClient)
          .then((responseUpdateClient: Client) => {
            const existedClientIndex = this.clients.findIndex(
              (client: Client) => client.id === responseUpdateClient.id
            );
            if (existedClientIndex > -1) {
              this.clients[existedClientIndex] = responseUpdateClient;
            }
            resolve(responseUpdateClient);
          })
          .catch((error: any) => reject(error));
      });
    },
    deleteClient(clientId: number): Promise<string> {
      return new Promise((resolve, reject) => {
        this.setClientInProcess(clientId, true);
        ClientService.deleteClient(clientId)
          .then((response: string) => {
            const existedClientIndex = this.clients.findIndex((client: Client) => client?.id === clientId);
            if (existedClientIndex > -1) {
              this.clients = this.clients.filter((client: Client) => client.id != clientId);
            }
            resolve(response);
          })
          .catch((error: any) => reject(error))
          .finally(() => this.setClientInProcess(clientId, false));
      });
    },
    setClientInProcess(clientId: number, currentValue: boolean) {
      const existedClientIndex = this.clients.findIndex((client: Client) => client?.id === clientId);
      if (existedClientIndex > -1) {
        this.clients[existedClientIndex].inProcess = currentValue;
      }
    },
    getClientQR(clientId: number): Promise<ClientQrResponse> {
      return new Promise((resolve, reject) => {
        ClientService.getClientQR(clientId)
          .then((clientQrResponse: ClientQrResponse) => {
            resolve(clientQrResponse);
          })
          .catch((error: any) => reject(error));
      });
    },
    refreshClientQR(clientId: number): Promise<string> {
      return new Promise((resolve, reject) => {
        ClientService.refreshClientQR(clientId)
          .then((qrImageString: string) => {
            resolve(qrImageString);
          })
          .catch((error: any) => reject(error));
      });
    },
    getClientContactList(clientId: number): Promise<Client[]> {
      return new Promise((resolve, reject) => {
        ClientService.getClientContactList(clientId)
          .then((contactList: Client[]) => {
            resolve(contactList);
          })
          .catch((error: any) => reject(error));
      });
    },
    getPreviewContactList(clientId: number | null, tagIds: number[]): Promise<Client[]> {
      return new Promise((resolve, reject) => {
        ClientService.getPreviewContactList(clientId, tagIds)
          .then((previewContactList: Client[]) => {
            resolve(previewContactList);
          })
          .catch((error: any) => reject(error));
      });
    }
  }
});
